body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  background-color: rgb(246, 247, 248);
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  font-family: system-ui, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  padding-bottom: 1px;
  padding-left: 1.5px;
  padding-right: 1.5px;
  padding-top: 1px;
  vertical-align: "middle";
}

/** MARKDOWN */

a {
  color: #24a1cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.alert {
  border-radius: 0.4rem;
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 17px;
  padding-top: 16px;
}

.alert-header {
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 0;
}

.alert-header-icon {
  font-size: 20px;
}
.alert-success {
  background-color: rgb(230, 246, 230);
  border-left: 5px solid #2e8555;
  color: rgb(0, 49, 0);
}

.alert-warning {
  background-color: rgb(255, 248, 230);
  border-left: 5px solid rgb(230, 167, 0);
  color: rgb(77, 56, 0);
}

.alert-info {
  background-color: rgb(241, 249, 253);
  border-left: 5px solid rgb(118, 176, 209);
  color: rgb(39, 59, 70);
}

.background_spin {
  animation-name: spin;
  animation-duration: 80s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

blockquote {
  background-color: rgba(246, 247, 248, 1);
  border-left: 5px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 17px;
  padding-top: 0px;
}

ol,
ul {
  line-height: 1.8;
  margin-bottom: 12px;
  margin-top: 0;
}

.yt {
  height: 315px;
  max-width: 560px;
  width: 100%;
}
